import axios from 'axios';

//Fonction pour formater les dates
export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
}

export function replaceLastTwoCharsWithZero(str) {
  if (!str) {
      // Gérer le cas où la chaîne est null ou undefined
      return '';
  }
  
  if (str.length >= 2) {
      return str.slice(0, -2) + '00';
  } else {
      // Gérer le cas où la chaîne est trop courte
      return str.padEnd(2, '0');
  }
}

/**************************Récupération des données************************** */
//Récupération des données de d'une facture seléctionnée
export const getInvoiceDatas = async (selected, isTemp) => {
    try {
        let response;
        if (isTemp) {
            console.log('Mode temporaire activé, récupération des données de facture temporaire');
            let url = `${process.env.VUE_APP_BASE_URL}/invoicetemp/${selected.invoice_id}`;
            response = await axios.get(url);
        } else {
            console.log('Mode permanent activé, récupération des données de facture');
            let url = `${process.env.VUE_APP_BASE_URL}/invoice/${selected.id}`;
            response = await axios.get(url);
        }

        if (response.data && typeof response.data === 'object') {
            console.log('Données de facture récupérées:', response.data);

            // Formatage des dates
            console.log('Formatage des dates de facture');
            if (response.data.invoice_date) {
                response.data.invoice_date = formatDate(response.data.invoice_date);
            }
            if (response.data.deadline) {
                response.data.deadline = formatDate(response.data.deadline);
            }
            console.log('Dates formatées: date de facture:', response.data.invoice_date, ', date limite:', response.data.deadline);

            return response.data;
        } else {
            throw new Error('Invalid response data');
        }
    } catch (error) {
        console.error('Erreur lors de la récupération des données de facture:', error);
        throw error;
    }
};

// Fonction pour récupérer le compte du stakeholder
export const getStakeholderAccount = async (companyId, stakeholderId) => {
    try {
        let url = `${process.env.VUE_APP_BASE_URL}/company_stakeholder/${companyId}/${stakeholderId}`;
        let response = await axios.get(url);
        return response.data[0].account;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

// Fonction pour récupérer le pays du stakeholder
export const getCountry = async (stakeholderId) => {
    try {
        let url = `${process.env.VUE_APP_BASE_URL}/stakeholder/${stakeholderId}`;
        let response = await axios.get(url);
        return response.data[0].country;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

// Fonction pour récupérer les informations du stakeholder
export const getStakeholderInfos = async (stakeholderId) => {
    try {
        let url = `${process.env.VUE_APP_BASE_URL}/stakeholder/${stakeholderId}`;
        let response = await axios.get(url);
        return response.data[0];
    } catch (error) {
        console.log(error);
        throw error;
    }
};

// Fonction pour récupérer les produits
export const getProducts = async (selected, isTemp) => {
    try {
        let url;
        if (isTemp) {
            url = `${process.env.VUE_APP_BASE_URL}/producttemp/${selected.invoice_id}`;
        } else {
            url = `${process.env.VUE_APP_BASE_URL}/product/${selected.id}`;
        }
        let response = await axios.get(url);
        const products = response.data.map((product, index) => {
            product.accountBis = product.account;
            return { ...product };
        });
        return products;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

//Fonction pour récuperer les noms de stock
export const getStocknameValue = async (companyId) => {
    try {
        const url = `${process.env.VUE_APP_BASE_URL}/stockname/${companyId}`;
        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        console.error('Erreur lors de la récupération des noms de stock:', error);
        throw error; // Rejeter l'erreur pour la gérer dans le composant appelant
    }
};

export const getIsDeposit = async () => {
    try {
      var url = `${process.env.VUE_APP_BASE_URL}/isDepositInvoice`;
      var response = await axios.post(url, { invoiceText: otherTable.value.fullText });
      if (response.data.isDeposit) {
        invoiceDatas.value.document_type = "acompte";
      }
    } catch (error) {
      console.log(error);
    }
  }

/**************************Fin de Récupération des données************************** */

/************************** Enregistrements ************************** */

export const registration = (key, account, invoiceDatas) => {
    const accountString = JSON.stringify(account).slice(1, -1);

    if (invoiceDatas.value.document_type === "facture" && accountString.startsWith("401")) {
      return "crédit";
    }

    if (invoiceDatas.value.document_type === "avoir" && accountString.startsWith("401")) {
      return "débit";
    }

    if (invoiceDatas.value.document_type === "facture" && accountString.startsWith("6") && key > 0) {
      return "débit";
    }

    if (invoiceDatas.value.document_type === "facture" && accountString.startsWith("6") && key < 0) {
      return "crédit";
    }
    if (invoiceDatas.value.document_type === "facture" && accountString.startsWith("2") && key > 0) {
      return "débit";
    }

    if (invoiceDatas.value.document_type === "facture" && accountString.startsWith("2") && key < 0) {
      return "crédit";
    }

    if (invoiceDatas.value.document_type === "avoir" && accountString.startsWith("6")) {
      return "crédit";
    }

    if (account.startsWith("409") && key > 0) {
      return "débit";
    }

    if (account.startsWith("409") && key < 0) {
      return "crédit";
    }



    if (invoiceDatas.value.document_type === "facture" && accountString.startsWith("4456")) {
      return "débit";
    }

    if (invoiceDatas.value.document_type === "avoir" && accountString.startsWith("4456")) {
      return "crédit";
    }

    if (invoiceDatas.value.document_type === "facture" && (accountString.startsWith("4457") || accountString.startsWith("4452"))) {
      return "crédit";
    }

    if (invoiceDatas.value.document_type === "avoir" && (accountString.startsWith("4457") || accountString.startsWith("4452"))) {
      return "débit";
    }
  };

/************************** Gestion de la TVA  ************************** */

  export const getTvaAccount = (country, invoiceDatas, products) => {
    var tvaRate = ["tva_20", "tva_13", "tva_10", "tva_5_5", "tva_2_10", "tva_0"];
    var accounts_1 = ["4456610020", "4456610013", "4456610010", "4456610055", "4456610021", ""];
    var accounts_2 = ["4456620020", "4456620013", "4456620010", "4456620055", "4456620021", ""];
    var accounts_3 = ["4456630020", "4456630013", "4456630010", "4456630055", "4456630021", ""];
    var accounts_4 = ["4457130020", "4457130013", "4457130010", "4457130055", "4457130021", ""];
    var ueCountry = ["Germany", "Austria", "Belgium", "Bulgaria", "Cyprus", "Croatia", "Denmark", "Spain", "Estonia", "Finland", "Greece", "Hungary", "Ireland", "Italy", "Latvia", "Lithuania", "Luxembourg", "Malta", "Netherlands", "Poland", "Portugal", "Czech Republic", "Romania", "Slovakia", "Slovenia", "Sweden"];
    var accounts_5 = ["4456660020", "4456660013", "4456660010", "4456660055", "4456660021", ""];
    var accounts_6 = ["4452000020", "4452000013", "4452000010", "4452000055", "4452000021", ""];
    var domTom = ["Guadeloupe", "Martinique", "Guyane", "Réunion", "Saint-Pierre-et-Miquelon", "Mayotte", "Terres Australes et Antarctiques Françaises", "Wallis et Futuna", "Polynésie Française", "Nouvelle Calédonie"];
    var accounts_7 = ["4456670020", "4456670013", "4456670010", "4456670055", "4456670021", ""];
    var accounts_8 = ["4457170020", "4457170013", "4457170010", "4457170055", "4457170021", ""];



    //Si le pays est la France et que le client tvaDebitOption est à true
    if (country == 'France' && invoiceDatas.tva_debit_option == true) {
      var array = []
      for (var i = 0; i <= tvaRate.length; i++) {
        var obj = {};
        obj[tvaRate[i]] = invoiceDatas[tvaRate[i]];
        obj["account1"] = accounts_1[i];
        array.push(obj);
      }
      return array;
    }
    //Si le pays est la France et que le client tvaDebitOption est à false
    else if (country == 'France' && invoiceDatas.tva_debit_option == false && (invoiceDatas.invoice_mention == false || invoiceDatas.invoice_mention == "false")) {
      var service = false;
      var immoTVA = {
        "tva_20": 0,
        "tva_13": 0,
        "tva_10": 0,
        "tva_5_5": 0,
        "tva_2_10": 0,
      }

      products.map(product => {
        if(product.data && product.data.immobilisation){
         let tvaKeys = Object.keys(product.data.immobilisation);
         tvaKeys.map(key=>immoTVA[key] += product.data.immobilisation[key])
        }
        if (product.account.startsWith("604") || product.account.startsWith("605") || product.account.startsWith("61") || product.account.startsWith("62") || product.account.startsWith("651")) {
          service = true;
        }
      })
      if (service) {
        var array = []
        for (var i = 0; i <= tvaRate.length; i++) {
          var obj = {};
          obj[tvaRate[i]] = invoiceDatas[tvaRate[i]];
          obj[tvaRate[i]] += immoTVA[tvaRate[i]]
          obj["account1"] = accounts_2[i];
          array.push(obj);
        }
        return array;
      } else {
        var array = []
        for (var i = 0; i <= tvaRate.length; i++) {
          var obj = {};
          obj[tvaRate[i]] = invoiceDatas[tvaRate[i]];
          obj[tvaRate[i]] += immoTVA[tvaRate[i]]
          obj["account1"] = accounts_1[i];
          array.push(obj);
        }
      
        return array;
      }
    } else if (country == "France" && invoiceDatas.invoice_mention == true) {
      var ht20 = 0;
      var ht13 = 0;
      var ht10 = 0;
      var ht55 = 0;
      var ht21 = 0;
      products.map(product => {
        if (product.tva_rate == 20) {
          ht20 += product.total_amount_ht;
        } else if (product.tva_rate == 13) {
          ht13 += product.total_amount_ht;
        } else if (product.tva_rate == 10) {
          ht10 += product.total_amount_ht;
        } else if (product.tva_rate == 5.5) {
          ht55 += product.total_amount_ht;
        } else if (product.tva_rate == 2.1) {
          ht21 += product.total_amount_ht;
        }
      })
      var tvaArray = [
        ht20 * 0.2, ht13 * 0.13, ht10 * 0.1, ht55 * 0.055, ht21 * 0.021, 0
      ];
      var array = []
      for (var i = 0; i <= tvaRate.length; i++) {
        var obj = {};
        obj[tvaRate[i]] = tvaArray[i];
        obj["account1"] = accounts_3[i];
        obj["account2"] = accounts_4[i];
        array.push(obj);
      }
      return array;
    }
    //Si le pays n'est pas la France mais en CEE
    else if (ueCountry.includes(country)) {
      var ht20 = 0;
      var ht13 = 0;
      var ht10 = 0;
      var ht55 = 0;
      var ht21 = 0;
      products.map(product => {
        if (product.tva_rate == 20) {
          ht20 += product.total_amount_ht;
        } else if (product.tva_rate == 13) {
          ht13 += product.total_amount_ht;
        } else if (product.tva_rate == 10) {
          ht10 += product.total_amount_ht;
        } else if (product.tva_rate == 5.5) {
          ht55 += product.total_amount_ht;
        } else if (product.tva_rate == 2.1) {
          ht21 += product.total_amount_ht;
        }
      })
      var tvaArray = [
        (ht20 * 0.2).toFixed(2),
        (ht13 * 0.13).toFixed(2),
        (ht10 * 0.1).toFixed(2),
        (ht55 * 0.055).toFixed(2),
        (ht21 * 0.021).toFixed(2),
        0
      ];
      console.log(tvaArray);
      var array = []
      for (var i = 0; i <= tvaRate.length; i++) {
        var obj = {};
        obj[tvaRate[i]] = tvaArray[i];
        obj["account1"] = accounts_5[i];
        obj["account2"] = accounts_6[i];
        array.push(obj);
      }
      console.log(array);
      return array;
    }
    //Si le pays n'est pas la France et pas en CEE ou c'est un domTom
    else if ((country != "France" && !ueCountry.includes(country)) || domTom.includes(country)) {
      var ht20 = 0;
      var ht13 = 0;
      var ht10 = 0;
      var ht55 = 0;
      var ht21 = 0;
      products.map(product => {
        if (product.tva_rate == 20) {
          ht20 += product.total_amount_ht;
        } else if (product.tva_rate == 13) {
          ht13 += product.total_amount_ht;
        } else if (product.tva_rate == 10) {
          ht10 += product.total_amount_ht;
        } else if (product.tva_rate == 5.5) {
          ht55 += product.total_amount_ht;
        } else if (product.tva_rate == 2.1) {
          ht21 += product.total_amount_ht;
        }
      })
      var tvaArray = [
        ht20 * 0.2, ht13 * 0.13, ht10 * 0.1, ht55 * 0.055, ht21 * 0.021, 0
      ];
      var array = []
      for (var i = 0; i <= tvaRate.length; i++) {
        var obj = {};
        obj[tvaRate[i]] = tvaArray[i];
        obj["account1"] = accounts_7[i];
        obj["account2"] = accounts_8[i];
        array.push(obj);
      }
      return array;
    }
  }

  export function getAllTvaAccountsAndAmounts(tvaAccounts) {
    let tvaDetails = [];
  
    // Parcourir les comptes TVA
    for (let account of tvaAccounts.value) {
      // Vérifier et ajouter chaque montant de TVA non nul avec son compte correspondant
      if (account.tva_20) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_20, tvaRate: "20%", registration: account.registration1  });
      if (account.tva_13) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_13, tvaRate: "13%", registration: account.registration1  });
      if (account.tva_10) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_10, tvaRate: "10%", registration: account.registration1  });
      if (account.tva_5_5) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_5_5, tvaRate: "5.5%", registration: account.registration1  });
      if (account.tva_2_10) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_2_10, tvaRate: "2.1%", registration: account.registration1  });
      if (account.tva_0) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_0, tvaRate: "0%", registration: account.registration1  });
  
      // Ajouter la gestion de TVA immobilisation pour chaque taux
      if (account.tva_immo_20) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_immo_20, tvaRate: "20%" });
      if (account.tva_immo_13) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_immo_13, tvaRate: "13%" });
      if (account.tva_immo_10) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_immo_10, tvaRate: "10%" });
      if (account.tva_immo_5_5) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_immo_5_5, tvaRate: "5.5%" });
      if (account.tva_immo_2_10) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_immo_2_10, tvaRate: "2.1%" });
      if (account.tva_immo_0) tvaDetails.push({ account_1: account.account1, account_2: account.account2, tvaAmount: account.tva_immo_0, tvaRate: "0%" });
    }
  
    return tvaDetails;
  }

  //Gestion des FEC
  export const formatFecEntry = (entry) => {
    return [
      entry.codeJournal,
      entry.libelleJournal,
      entry.numeroEcriture,
      entry.dateCompta,
      entry.numeroCompte,
      entry.libelleCompte,
      entry.numeroCompteAux,
      entry.libelleCompteAux,
      entry.referencePiece,
      entry.datePiece,
      entry.libelleEcriture,
      entry.montantDebit,
      entry.montantCredit,
      entry.lettrage,
      entry.dateLettrage,
      entry.dateValidation,
      entry.montantDevise,
      entry.idDevise
    ].join(';');
  }
  
  export async function downloadFecFile() {
    try {
      //Récupération des factures validées
      let url = `${process.env.VUE_APP_BASE_URL}/validatedInvoices/${companySelected.value.id}`;
      let response = await axios.get(url);
      let invoices = response.data;
      console.log(invoices)
      //Récupération de la dernière session (session la plus élevée)
      let lastInvoices = invoices.filter(invoice => invoice.session_number === Math.max(...invoices.map(invoice => invoice.session_number)));
      console.log(lastInvoices);
      //Pour chaque facture de la dernière session, on récupère les fec
      var fec = "";
      for (let invoice of lastInvoices) {
        let url2 = `${process.env.VUE_APP_BASE_URL}/invoiceFec/${invoice.id}`;
        let response2 = await axios.get(url2);
        console.log(response2.data)
        //Ajout au fec avec un retour à la ligne sauf pour la première facture et pour la dernière
        if (response2.data.length > 0) {
          fec += response2.data[0].fec + "\n";

        }
      }
      //Création du fichier à partir du fec
      // Créer un objet Blob avec le contenu du fichier
      let blob = new Blob([fec], { type: 'text/plain' });

      // Créer un lien pour le téléchargement
      let downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      //Création du nom en fonction de la date
      let date = new Date();
      let name = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
      downloadLink.download = `export_${name}.fec`;

      // Simuler un clic sur le lien pour déclencher le téléchargement
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Nettoyer et supprimer le lien
      document.body.removeChild(downloadLink);

    } catch (error) {
      console.log(error);
    }
  }

  //Gestion de la validation
  export const getValidatedInvoices = async () => {
    try {
      let date = new Date();
      let formattedDate = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
      const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/validatedInvoices/${companySelected.value.id}/${formattedDate}`);
      validatedInvoices.value = response.data;
    } catch (error) {
      console.log(error);
    }
  }