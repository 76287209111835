<template>
  <q-btn label="Create Recovery Templates" @click="openDialog" color="primary" />

  <q-dialog v-model="dialogVisible">
    <q-card>
      <q-card-section>
        <div class="text-h6">Create Recovery Templates</div>
      </q-card-section>

      <q-card-section>
        <q-form @submit.prevent="submitForm">
          <q-input filled v-model="variables.accountHolder" label="Account Holder" />
          <q-input filled v-model="variables.rib" label="RIB" />
          <q-input filled v-model="variables.iban" label="IBAN" />
          <q-input filled v-model="variables.bic" label="BIC" />

          <div class="q-mt-md">
            <q-btn label="Submit" type="submit" color="primary" />
            <q-btn label="Cancel" flat @click="dialogVisible = false" />
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { ref, computed } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';

const store = useStore();
const companySelected = computed(() => store.state.companySelected);

const dialogVisible = ref(false);
const variables = ref({
  accountHolder: '',
  rib: '',
  iban: '',
  bic: ''
});

// Fonction pour ouvrir la boîte de dialogue et récupérer les détails de la banque
const openDialog = async () => {
  dialogVisible.value = true;
  await fetchBankDetails();
};

// Fonction pour récupérer les détails de la banque à partir de l'API
const fetchBankDetails = async () => {
  try {
    if (!companySelected.value || !companySelected.value.evoliz_id) {
      console.error("Company ID is missing");
      return;
    }

    const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/bankdetails/${companySelected.value.id}`);
    const bankDetails = response.data;

    // Remplir les champs du formulaire avec les détails de la banque récupérés
    variables.value.accountHolder = bankDetails.name;
    variables.value.rib = bankDetails.RIB;
    variables.value.iban = bankDetails.IBAN;
    variables.value.bic = bankDetails.BIC;
  } catch (error) {
    console.error('Error fetching bank details:', error);
  }
};

const submitForm = async () => {
  try {
    const payload = {
      variables: variables.value,
      evolizCompanyId: companySelected.value.evoliz_id
    };
    await createRecoveryTemplates(payload);
    dialogVisible.value = false;
  } catch (error) {
    console.log(error);
  }
};

const createRecoveryTemplates = async (payload) => {
  try {
    const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/createRecoveryTemplates`, payload);
    if (!response.data.success) {
      throw new Error('Failed to create recovery templates');
    }
    return response.data;
  } catch (error) {
    console.error('Error creating recovery templates:', error);
    throw error;
  }
};
</script>

<style scoped>
</style>
