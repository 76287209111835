<template>
  <div class="menu">
    <q-list style="min-width: 300px; min-height: 90vh">
      <q-item clickable @click="moveToGed">
        <q-item-section side>
          <q-icon color="primary" name="folder" />
        </q-item-section>
        <q-item-section>GED</q-item-section>
      </q-item>
       <q-item clickable @click="moveToSupplier">
        <q-item-section side>
          <q-icon color="primary" name="contacts" />
        </q-item-section>
        <q-item-section>Fournisseurs</q-item-section>
      </q-item>
      <q-item clickable @click="moveToMail">
        <q-item-section side>
          <q-icon color="primary" name="mail" />
        </q-item-section>
        <q-item-section>Mail</q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script>
import router from "@/router";
import { getCurrentInstance } from "vue";

export default {
  setup() {
    const internalInstance = getCurrentInstance();
    const emitter = internalInstance.appContext.config.globalProperties.emitter;
    const moveToAdd = () => {
      router.push({ path: "/addCompany/" });
      emitter.emit("moveToOther");
    };
    const moveToGed = () => {
      router.push({ path: "/ged/" });
      emitter.emit("moveToGed");
    };
    const moveToSupplier = () => {
      router.push({ path: "/supplier/" });
      emitter.emit("moveToOther");
    };
     const moveToMail = () => {
      router.push({ path: "/mail/" });
      emitter.emit("moveToOther");
    };
    return {
      moveToAdd,
      moveToGed,
      moveToSupplier,
      moveToMail
    };
  },
};
</script>

<style>
.menu {
  z-index: 999;
  position: fixed;
  left: 0;
  background-color: white;
}
</style>