<template>
  <div style="display: flex; align-items: center;">
    <q-select style="width:200px;" dense filled v-model="companySelected" :options="companies" label="Sélectionner le client"
      option-label="corporate_name" emit-value map-options @update:model-value="companyHandleSelect" />

    <q-tabs v-model="tab" class="text-indigo-14">
      <!--<q-tab name="contacts" icon="contacts" label="Contacts" @click="moveToPartner" />-->
      <!--<q-tab name="mails" icon="mail" label="Mails" @click="moveToMail" />-->
      <q-tab name="addCompany" icon="add_business" label="Ajouter une entreprise" @click="moveToAdd" />
      <!-- <q-tab name="addWorkforce" icon="group" label="WorkForce" @click="moveToWorkforce" />-->
      <q-tab name="zoho" icon="pets" label="Connexion Zoho" @click="moveToZoho" />
      <q-tab name="GED" icon="group" label="GED" @click="moveToGed" />
      <q-tab name="file" icon="description" label="Fichiers" @click="moveToFile" />
      <q-tab name="preview" icon="support_agent" label="IA" @click="moveToIAPreview" />
      <q-tab name="stock" icon="inventory_2" label="Stock" @click="moveToStock" />
      <q-tab name="evoliz" icon="savings" label="Evoliz" @click="moveToEvoliz" />
    </q-tabs>
    <q-btn dense label="Ouvrir Zoho" color="purple" @click="openZoho" />
    <q-btn dense label="Ouvrir Evoliz" color="purple" @click="openEvoliz" />

  </div>
</template>

<script>
import router from "@/router";
import { ref, onMounted } from "vue";
import axios from "axios";
import { useStore } from "vuex";

export default {
  setup() {
    let tab = ref();
    const store = useStore();
    const companies = ref([]);
    const companySelected = ref(null);
    const start = () => {
      getCompanies();
    }

    const getCompanies = async () => {
      try {
        let url = `${process.env.VUE_APP_BASE_URL}/companies`;
        const response = await axios.get(url);
        companies.value = response.data;
      } catch (error) {
        console.error(error);
      }
    }


    const companyHandleSelect = async (value) => {
      companySelected.value = value;
      store.commit('setCompanySelected', companySelected.value)
      console.log(companySelected.value)
    }
    const moveToAdd = () => {
      router.push({ path: "/addCompany/" });
    };
    const moveToGed = () => {
      router.push({ path: "/ged/" });
    };
    const moveToPartner = () => {
      router.push({ path: "/partners/" });
    };
    const moveToMail = () => {
      router.push({ path: "/mail/" });
    };
    const moveToIAPreview = () => {
      router.push({ path: "/iapreview2/" });
    };
    const moveToZoho = () => {
      router.push({ path: "/zoho/" });
    };

    const moveToWorkforce = () => {
      router.push({ path: "/workforce/" });
    };

    const moveToFile = () => {
      router.push({ path: "/file/" });
    };

    const moveToEvoliz = () => {
      router.push({ path: "/evoliz/" });
    };

    const moveToStock = () => {
      router.push({path: "/stock/"});
    }

    const openZoho = () => {
      window.open('https://workdrive.zoho.eu/home/', '_blank');
    };

    const openEvoliz = () => {
      window.open('https://www.evoliz.com', '_blank');
    };

    onMounted(start);

    return {
      companies,
      companySelected,
      moveToAdd,
      moveToGed,
      moveToPartner,
      moveToMail,
      moveToIAPreview,
      moveToZoho,
      moveToWorkforce,
      moveToFile,
      moveToEvoliz,
      companyHandleSelect,
      tab,
      openZoho,
      openEvoliz,
      moveToStock
    };
  },
};
</script>

<style></style>