<template>
      <q-btn label="Update Templates" @click="openDialog" color="primary" />
  
      <q-dialog v-model="dialogVisible">
        <q-card>
          <q-card-section>
            <div class="text-h6">Update Templates</div>
          </q-card-section>
  
          <q-card-section>
            <q-form @submit="submitForm">
              <q-input filled v-model="variables.colorGauche" label="Color Gauche">
                <template v-slot:append>
                  <q-btn flat icon="colorize" @click="pickColor('colorGauche')" />
                </template>
              </q-input>
              <q-input filled v-model="variables.colorDroite" label="Color Droite">
                <template v-slot:append>
                  <q-btn flat icon="colorize" @click="pickColor('colorDroite')" />
                </template>
              </q-input>
              <q-input filled v-model="variables.colorTexte" label="Color Texte">
                <template v-slot:append>
                  <q-btn flat icon="colorize" @click="pickColor('colorTexte')" />
                </template>
              </q-input>
              <q-input filled v-model="variables.typeContrat" label="Type de Contrat" />
              <q-input filled v-model="variables.numeroContrat" label="Numéro de Contrat" />
  
              <div class="q-mt-md">
                <q-btn label="Submit" type="submit" color="primary" />
                <q-btn label="Cancel" flat @click="dialogVisible = false" />
              </div>
            </q-form>
          </q-card-section>
        </q-card>
  
        <q-color v-model="color" v-if="colorPickerVisible" @hide="colorPickerVisible = false" />
      </q-dialog>
  </template>
  
  <script setup>
  import { ref, watch, computed } from 'vue';
  import { useStore } from 'vuex';
  import axios from 'axios';
  const store = useStore();
  const companySelected = computed(() => store.state.companySelected);
  const dialogVisible = ref(false);
  const colorPickerVisible = ref(false);
  const color = ref('#ffffff');
  const colorTarget = ref('');
  const variables = ref({
    colorGauche: '#ff0000',
    colorDroite: '#00ff00',
    colorTexte: '#0000ff',
    typeContrat: 'Nouveau Contrat',
    numeroContrat: 'Nouveau Numéro de Contrat'
  });
  
  const openDialog = () => {
    dialogVisible.value = true;
  };
  
  const pickColor = (target) => {
    colorTarget.value = target;
    colorPickerVisible.value = true;
  };
  
  watch(color, (newColor) => {
    if (colorPickerVisible.value) {
      variables.value[colorTarget.value] = newColor;
    }
  });
  
  const submitForm = async () => {
    try {
      const payload = {
        variables : variables.value,
        evolizCompanyId: companySelected.value.evoliz_id
      };
      await updateTemplates(payload);
      dialogVisible.value = false;
    } catch (error) {
      console.log(error);
    }
  };
  
  const updateTemplates = async (payload) => {
    // Remplacez cette URL par l'URL correcte de votre API
    const response = await axios.post(`${process.env.VUE_APP_BASE_URL}/updateTemplates`, payload);
    

    if (!response.ok) {
      throw new Error('Failed to update templates');
    }
  
    return response.json();
  };
  </script>
  
  <style scoped>
  .q-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  </style>
  