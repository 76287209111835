<template>
  <div>
    <div>
      <q-dialog v-model="updateDialog">
        <q-card style="width:50%">
          <q-card-section>
            <div class="text-h6">Modifier un compte mail</div>
          </q-card-section>
          <q-card-section>
            <q-input color="orange-1-12" v-model="mail" label="Adresse mail">
              <template v-slot:prepend>
                <q-icon name="mail" />
              </template>
            </q-input>
            <q-input
              color="orange-1-12"
              v-model="userName"
              label="Nom d'utilisateur"
            >
              <template v-slot:prepend>
                <q-icon name="person" />
              </template>
            </q-input>
            <q-input color="orange-1-12" v-model="password" label="Mot de passe">
              <template v-slot:prepend>
                <q-icon name="key" />
              </template>
            </q-input>
            <q-input color="orange-1-12" v-model="host" label="Hôte">
              <template v-slot:prepend>
                <q-icon name="gite" />
              </template>
            </q-input>
            <q-input color="orange-1-12" v-model="port" label="Port">
              <template v-slot:prepend>
                <q-icon name="sailing" />
              </template>
            </q-input>
          </q-card-section>

          <br />
          <q-card-section>
            <q-btn color="primary" label="Modifier" @click="updateMail" />
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
    <div class="mailsList">
    <h4 style="text-align:center; font-size:25px">Liste des comptes mails</h4>
      <q-list bordered>
        <q-item v-for="(contact, index) in mails" :key="contact.id">
          <q-item-section avatar>
            <q-avatar color="orange-14" text-color="white">
              {{ contact.mail["mail"].charAt(0).toUpperCase() }}
            </q-avatar>
          </q-item-section>

          <q-item-section>
            <q-item-label>{{ contact.mail["mail"] }}</q-item-label>
          </q-item-section>
          <q-item-section>
            <q-item-label>
              <q-btn-dropdown color="orange-1"  class="text-black" label="Options">
                <q-list>
                  <q-item clickable v-close-popup @click="showMailDialog(index)">
                    <q-item-section>
                      <q-item-label>Modifier</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item clickable v-close-popup @click="deleteMail(contact.id)">
                    <q-item-section>
                      <q-item-label>Supprimer</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown></q-item-label
            >
          </q-item-section>
          <q-item-section>
             <q-toggle
        v-model="invoiceCron[index]"
        color="green-14"
        label="Récupération des factures"
      />
          </q-item-section>
        </q-item>
      </q-list>
    </div>
    <div class="banners">
      <q-banner v-if="saved" class="bg-secondary text-white">
        Données enregistrées
      </q-banner>
      <q-banner v-if="errorSaved" class="text-white bg-red">
        Erreur pendant l'enregistrement
      </q-banner>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import axios from "axios";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    let mails = ref([]);
    let user = store.state.currentClient;
    let updateDialog = ref(false);
    let mail = ref();
    let userName = ref();
    let password = ref();
    let host = ref();
    let port = ref();
    let mailId = ref();
    let saved = ref(false);
    let errorSaved = ref(false);
    let invoiceCron = ref([])
    const getMails = async () => {
      try {
        let url = `${process.env.VUE_APP_BASE_URL}/mails/` + user;
        let response = await axios.get(url);
        mails.value = response.data;
        console.log(mails.value);
      } catch (error) {
        console.log(error);
      }
    };

    const updateMail = async () => {
      try {
        let putOptions = {
            mail: mail.value,
            userName: userName.value,
            password: password.value,
            host: host.value,
            port: port.value,
        }
        let url = `${process.env.VUE_APP_BASE_URL}/mails/` + mailId.value;
        await axios.put(url, putOptions);
        saved.value=true;
        updateDialog.value = false
        setTimeout(()=>{
          saved.value=false;
        }, 1500)
      } catch (error) {
        console.log(error);
      }
    };
    const showMailDialog = (index) => {

      updateDialog.value = true;
      let currentMail = mails.value[index];
      mailId.value = currentMail.id;
      mail.value = currentMail.mail.mail;
      userName.value = currentMail.mail.userName;
      password.value = currentMail.mail.password;
      host.value = currentMail.mail.host;
      port.value = currentMail.mail.port;
    };
    const deleteMail = async (id) => {
      try {
        console.log(id);
        let url = `${process.env.VUE_APP_BASE_URL}/mail/` + id;
        await axios.delete(url);
        saved.value=true;
        setTimeout(()=>{
          saved.value=false;
        }, 1500)
      getMails();
      } catch (error) {
        console.log(error);
      }
    };
    const start = () => {
      getMails();
    };
    onMounted(start);
    return {
      mails,
      updateMail,
      deleteMail,
      updateDialog,
      showMailDialog,
      mail,
      userName,
      password,
      host,
      port,
      mailId,
      saved,
      errorSaved,
      invoiceCron
    };
  },
};
</script>

<style>
.mailsList {
  width: 60%;
  margin: auto;
}
</style>