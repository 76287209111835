<template lang="">
    <div>
        <div>
            <h3 style="text-align:center; font-size:25px"><b>GESTION DES FICHIERS</b></h3>
        </div>
        <div class="select-container">
            <div class='date-selector'>
                <q-input filled v-model="startDate" mask="date" :rules="['date']" label="Date de début">
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                            <q-date v-model="startDate">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Close" color="primary" flat />
                            </div>
                            </q-date>
                        </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>   
                <q-input filled v-model="endDate" mask="date" :rules="['date']" label="Date de fin">
                    <template v-slot:append>
                        <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                            <q-date v-model="endDate">
                            <div class="row items-center justify-end">
                                <q-btn v-close-popup label="Close" color="primary" flat />
                            </div>
                            </q-date>
                        </q-popup-proxy>
                        </q-icon>
                    </template>
                </q-input>    
            </div>
            <div>
                <q-select filled v-model="companySelected" :options="companies" label="Sélectionner le client" option-value="id"
                option-label="corporate_name" emit-value map-options @update:model-value="companyHandleSelect" />
            </div>
            

        </div>
        <div v-if="files.length>0">
            <table class="styled-table">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Type</th>
                        <th>Dossier de déstination</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="file in files" :key="file.id">
                        <td>{{ file.name }}</td>
                        <td>{{ file.type }}</td>
                        <td>{{ file.folder_type }}</td>
                        <td>
                            <q-btn @click="update(file)" color="grey">Modifier</q-btn>
                        </td>
                    </tr>
                </tbody>
            </table>
            
        </div>
        <q-dialog v-model="updateFolderDialog" persistent>
      <q-card>
        <q-card-section>
          <div class="text-h6">Sélectionnez un dossier</div>
        </q-card-section>

        <q-card-section>
          <q-select
            v-model="selectedFolder"
            :options="folders"
            label="Dossiers"
            use-input
            fill-input
            hide-selected
            input-debounce="0"
            emit-value
            map-options
            option-label="folder_type"
            
          />
        </q-card-section>
       <q-card-section>
            <q-input filled v-model="type" label="Type" />
       </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Annuler" color="primary" v-close-popup />
          <q-btn flat label="Confirmer" color="primary" @click="handleUpdateFile" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
export default {

    setup() {
        const companies = ref([]);
        const companySelected = ref(null);
        const startDate = ref(null);
        const endDate = ref(null);
        const files = ref([]);
        const folders = ref([]);
        const updateFolderDialog = ref(false)
        const fileSelected = ref(null);
        const selectedFolder = ref(null);
        const type = ref(null);
        const start = async () => {
            getCompanies();
        }

        const getFoldersFromCompany = async () => {
            let url = `${process.env.VUE_APP_BASE_URL}/foldersFromCompany/${companySelected.value}`;
            try {
                const response = await axios.get(url);
                folders.value = response.data;
            } catch (error) {
                console.error(error);
            }
        }

        const getCompanies = async () => {
            try {
                let url = `${process.env.VUE_APP_BASE_URL}/companies`;
                let response = await axios.get(url);
                companies.value = response.data;

            } catch (error) {
                console.log(error);
            }
        }

        const companyHandleSelect = (value) => {
            companySelected.value = value;
            getFiles();

        }

        const getFiles = async () => {
            let startDateFormatted = encodeURIComponent(startDate.value);
            let endDateFormatted = encodeURIComponent(endDate.value);
            let url = `${process.env.VUE_APP_BASE_URL}/filesDatas/${companySelected.value}/${startDateFormatted}/${endDateFormatted}`;
            try {
                const response = await axios.get(url);
                console.log(response.data);
                files.value = response.data;
            } catch (error) {
                console.error(error);
            }
        }

        const update = async (file) => {
            await getFoldersFromCompany();
            updateFolderDialog.value = true;
            fileSelected.value = file;
            type.value = file.type;
        }

        const handleUpdateFile = () =>{
            console.log(fileSelected.value, selectedFolder.value)
            let url = `${process.env.VUE_APP_BASE_URL}/file`;
            let data = {
                id: fileSelected.value.id,
                parentId: selectedFolder.value.id,
                type: type.value
            }
            axios.put(url, data)
            updateFolderDialog.value = false;
            getFiles();
        }

        const moveFileInZoho = () => {
            let url = `${process.env.VUE_APP_BASE_URL}/moveFileInZoho`;
            axios.post(url, {company_id: companySelected.value})
        }

        onMounted(() => {
            start();
        });
        return {
            companies,
            companySelected,
            startDate,
            endDate,
            files,
            updateFolderDialog,
            folders,
            selectedFolder,
            type,
            companyHandleSelect,
            update,
            handleUpdateFile
        }
    }

}
</script>
<style >
.date-selector {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.styled-table {
    width: 80%;
    margin: 20px 0;
    border-collapse: collapse;
    font-size: 0.9em;
    font-family: sans-serif;
    text-align: left;
    margin: auto;
}

.styled-table thead tr {
    background-color: #304FFD;
    color: white;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #304FFD;
}
</style>