<template>
  <div class="container_login" >
    <div class="form-container">
      <div>
        <h2 class="title">Connexion à votre compte TaaC</h2>
      </div>

      <form @submit.prevent="signInWithEmail" class="form">
        <div class="form-group">
          <label for="email" class="sr-only">Adresse e-mail</label>
          <input id="email" name="email" type="email" autocomplete="email" v-model="email" required
            class="input" placeholder="Adresse e-mail">
        </div>
        <div class="form-group">
          <label for="password" class="sr-only">Mot de passe</label>
          <input id="password" name="password" type="password" autocomplete="current-password" v-model="password"
            required class="input" placeholder="Mot de passe">
        </div>

        <div v-if="authError" class="error-message">
          {{ authError }}
        </div>

        <div class="form-footer">
          <a href="#" class="forgot-password">Mot de passe oublié ?</a>
        </div>

        <div>
          <button type="submit" class="btn-submit">Se connecter</button>
        </div>
      </form>

      <div class="register-link">
        <p class="text">
          Pas de compte ?
          <a href="#" class="create-account">Créer un compte</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'; // Utilisation de l'API modulaire de Firebase

const email = ref('');
const password = ref('');
const authError = ref('');
const router = useRouter();

// Récupérer l'instance d'authentification
const auth = getAuth();

const signInWithEmail = async () => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email.value, password.value);
    const user = userCredential.user;
    router.push("/");
  } catch (error) {
    console.error('Error signing in:', error);
    authError.value = 'E-mail ou mot de passe incorrect';
  }
};
</script>

<style scoped>

.container_login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* La hauteur de la fenêtre visible */
  width: 100vw; /* La largeur de la fenêtre visible */
  background-color: #f0f0f0;
  background-color: #FFFFFF;
  z-index: 9999;
  position: fixed;
  top: 0;
}

.form-container {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.title {
  text-align: center;
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1.5rem;
}

.input {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input:focus {
  border-color: #007bff;
  outline: none;
}

.btn-submit {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-submit:hover {
  background-color: #0056b3;
}

.forgot-password {
  display: inline-block;
  margin-top: 0.5rem;
  color: #007bff;
  text-decoration: none;
  font-size: 0.875rem;
}

.forgot-password:hover {
  text-decoration: underline;
}

.register-link {
  text-align: center;
  margin-top: 2rem;
}

.create-account {
  color: #007bff;
  text-decoration: none;
}

.create-account:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  text-align: center;
  font-size: 0.875rem;
  margin-top: 1rem;
}

.text {
  font-size: 0.875rem;
  color: #555;
}
</style>
