<template>
    <div>
        <div>
            <q-toggle v-model="isTemp" label="Temporaire" @update:model-value="handleTemp" />
        </div>
        <p>Filtres & Tris</p>

        <div class="rows ">
            <q-input class="q-col" filled v-model="filters.supplier_name" label="Nom du fournisseur"
                @input="applyFiltersAndSort" dense />
            <q-input class="q-col" filled v-model="filters.invoice_date" type="date" @input="applyFiltersAndSort"
                dense />
            <q-select class="q-col" filled v-model="sort.column" :options="sortOptions" label="Trier par"
                @input="applyFiltersAndSort" dense />
            <q-select class="q-col" filled v-model="sort.order" :options="['asc', 'desc']" label="Ordre de tri"
                @input="applyFiltersAndSort" dense />
            <q-btn class="q-col-auto q-pa-xs custom-btn" color="primary" label="Appliquer" @click="applyFiltersAndSort"
                dense />
        </div>
        <br>
        <q-select style="width:25%; margin: 5px;" filled v-model="selected" :options="filteredOptions"
            label="Sélectionner le fichier" option-label="name" emit-value map-options
            @update:model-value="emitInvoiceSelectEvent" dense />
    </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useQuasar } from 'quasar';
import { useStore } from 'vuex';
import axios from 'axios';
export default {
    name: 'FilterSortComponent',
    emits: ['invoiceSelected', 'isTemp'],
    setup(props, ctx) {
        const store = useStore();
        const quasar = useQuasar();
        const isTemp = ref(true);
        const filters = ref({
            supplier_name: '',
            invoice_date: '',
        });
        const sort = ref({
            column: 'invoice_date',
            order: 'asc',
        });
        const sortOptions = ref([
            { label: 'Id de la facture', value: 'id' },
            { label: 'Date de la facture', value: 'invoice_date' },
            { label: 'Nom du fournisseur', value: 'supplier_name' },
            { label: 'Numéro de facture', value: 'invoice_number' },
            { label: 'Total TTC', value: 'total_ttc' },
        ]);
        const selected = ref(null);
        const filteredOptions = ref([]);
        const companySelected = computed(() => store.state.companySelected);
        const options = ref([])
        const handleTemp = () => {
            ctx.emit('isTemp', isTemp.value)
        };

        const applyFiltersAndSort = () => {
            let filtered = [...options.value];

            if (filters.value.supplier_name) {
                filtered = filtered.filter(option =>
                    option.supplier_name.toLowerCase().includes(filters.value.supplier_name.toLowerCase())
                );
            }

            if (filters.value.invoice_date) {
                filtered = filtered.filter(option =>
                    option.invoice_date === filters.value.invoice_date
                );
            }

            filtered.sort((a, b) => {
                let modifier = 1;
                if (sort.value.order === 'desc') modifier = -1;

                const column = sort.value.column.value;
                const aValue = a[column];
                const bValue = b[column];


                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    const result = aValue.localeCompare(bValue) * modifier;
                    return result;
                } else if (typeof aValue === 'number' && typeof bValue === 'number') {
                    const result = (aValue - bValue) * modifier;
                    return result;
                } else if (new Date(aValue) instanceof Date && !isNaN(new Date(aValue)) && new Date(bValue) instanceof Date && !isNaN(new Date(bValue))) {
                    const result = (new Date(aValue).getTime() - new Date(bValue).getTime()) * modifier;
                    return result;
                } else {
                    if (aValue < bValue) {
                        return -1 * modifier;
                    }
                    if (aValue > bValue) {
                        return 1 * modifier;
                    }
                    return 0;
                }
            });

            filteredOptions.value = filtered;
        };

        // récupérer les données en fonction de la société sélectionnée
        const getData = async () => {
            try {
                if (isTemp.value) {
                    let url = `${process.env.VUE_APP_BASE_URL}/invoicestemp/${companySelected.value.id}`;
                    let response = await axios.get(url);
                    options.value = response.data;
                    applyFiltersAndSort();
                } else {
                    let url = `${process.env.VUE_APP_BASE_URL}/invoices/${companySelected.value.id}`;
                    let response = await axios.get(url);
                    options.value = response.data;
                    applyFiltersAndSort();

                }
            } catch (error) {
                console.error(error);
            }
        };

        const emitInvoiceSelectEvent = () => {
            ctx.emit('invoiceSelected', selected.value);

        }

        watch(() => companySelected.value, async (newVal, oldVal) => {
            if (newVal !== oldVal) {
                await getData();
            }
        }, { immediate: true });

        watch(() => isTemp.value, async () => {
            try {
                if (isTemp.value) {
                    let response = await axios.get(`${process.env.VUE_APP_BASE_URL}/invoicestemp/${companySelected.value.id}`);
                    options.value = response.data;
                    applyFiltersAndSort();
                } else {
                    let response = await axios.get(`${process.env.VUE_APP_BASE_URL}/invoices/${companySelected.value.id}`);
                    options.value = response.data;
                    applyFiltersAndSort();
                }
            } catch (error) {
                console.log(error);
            }
        })

        return {
            isTemp,
            filters,
            sort,
            selected,
            filteredOptions,
            handleTemp,
            applyFiltersAndSort,
            options,
            sortOptions,
            companySelected,
            emitInvoiceSelectEvent
        };
    }
};
</script>

<style scoped>
.rows {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 90%;
    gap: 8px;
    margin: 10px;
}

.q-col {
    flex: 1 1 0;
    max-width: 90%;
}

.q-col-auto {
    flex: 0 1 auto;
}
</style>