<template>
    <div class="zoho-login">
        <div class="login-button">
            <button @click="handleLogin">Se connecter avec Zoho</button>
        </div>
        <div v-if="authCode" class="auth-code-section">
            <h2>Code d'Authentification Zoho</h2>
            <input class="auth-code-input" v-model="authCode" readonly>
            <button class="copy-button" @click="copyCode">Copier le Code</button>
        </div>
        <div>
            <h5>Token d'accès</h5>
            <input class="auth-code-input" placeholder="Identifiant de l'entreprise" type="text" v-model="companyId">
            <button class="copy-button" @click="postAccessToken">Générer le token d'accès</button>
        </div>
        <div>
            <div v-if="successMessage" class="notification success">
                {{ successMessage }}
            </div>

            <div v-if="errorMessage" class="notification error">
                {{ errorMessage }}
            </div>
        </div>
        <br>
    </div>
</template>

  
<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axios from "axios";

const authCode = ref('');
const route = useRoute();
const clientId = '1000.UKQ2F9ZDMWFEFV6P30TI9EJHVGN25H';
const redirectUri = encodeURIComponent('http://localhost:8080/zoho');
const scopes = 'WorkDrive.team.ALL,WorkDrive.members.ALL,WorkDrive.teamfolders.ALL,WorkDrive.teamfolders.sharing.ALL,WorkDrive.teamfolders.admin.READ,WorkDrive.groups.ALL,WorkDrive.files.ALL,WorkDrive.files.READ,ZohoFiles.files.READ,WorkDrive.links.ALL,WorkDrive.comments.ALL,WorkDrive.collection.ALL,WorkDrive.datatemplates.ALL,WorkDrive.labels.ALL,WorkDrive.libraries.ALL,WorkDrive.libraries.sharing.READ,WorkDrive.libraries.categories.ALL';
const responseType = 'code';
const accessType = 'offline'; // ou 'online'
const companyId = ref();
const successMessage = ref();
const errorMessage = ref();

function handleLogin() {
    const authUrl = `https://accounts.zoho.eu/oauth/v2/auth?scope=${scopes}&client_id=${clientId}&response_type=${responseType}&access_type=${accessType}&redirect_uri=${redirectUri}&state=register&prompt=consent`;
    window.location.href = authUrl;
} onMounted(() => {
    console.log(route.query)
    if (route.query.code) authCode.value = route.query.code;
});

const copyCode = () => {
    navigator.clipboard.writeText(authCode.value).then(() => {
        console.log('Code copié avec succès');
    }).catch(err => {
        console.error('Erreur lors de la copie du code', err);
    });
};

const postAccessToken = async () => {
    try {
        let url = `${process.env.VUE_APP_BASE_URL}/generate-token`;
        let response = await axios.post(url, { "companyId": companyId.value });
        if (response.status === 200) {
                    successMessage.value = 'Token généré avec succès.';
                    errorMessage.value = '';
                }
    } catch (error) {
        console.log(error);
        errorMessage.value = 'Erreur lors de la génération du token.';
        successMessage.value = '';
    }
}
 
</script>
  

<style scoped>
.zoho-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.login-button {
    margin-bottom: 20px;
}

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #0056b3;
}

.auth-code-section {
    text-align: center;
}

.auth-code-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.copy-button {
    background-color: #28a745;
}

.copy-button:hover {
    background-color: #218838;
}
</style>