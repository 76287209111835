<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <q-icon name="folder" class="text-teal" size="xl"></q-icon>
            <span name="header"> Créer un dossier </span>
          </div>

            <q-form
      @submit="onSubmit"
      @reset="onReset"
      class="q-gutter-md"
    >
      <q-input
        v-model="name"
        label="Nom du dossier"
        lazy-rules
        :rules="[ val => val && val.length > 0 || 'Please type something']"
      />
      <div>
        <q-btn label="Annuler" color="primary" flat class="q-ml-sm" @click="$emit('close')" />
        <q-btn label="Créer"  color="primary" @click="create"/>
      </div>
    </q-form>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';
import {ref} from 'vue';
import {onMounted } from '@vue/runtime-core';
import { useStore } from "vuex";

export default {
    props: {
      id: Number
    },
    setup(props, context){
        const store = useStore();
        let name = ref();
        let parentId = ref(null);
        const create = async () =>{
          console.log("create")
            try {
                let query = `${process.env.VUE_APP_BASE_URL}/folder`;
                let body = {
                    name: name.value,
                    parentId : parentId.value,
                    id_client : store.state.currentClient
                }
                await axios.post(query, body);
                context.emit('folderAdded');
            } catch (error) {
                console.log(error);
            }
        }
        const test = () => {
          console.log("props : " + props.id);
          if(props.id == undefined) parentId.value = null;
          else parentId.value = props.id
        }
        onMounted(test);
        return{
            name,
            create,
            test
        }
    }
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 500px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header span {
  margin-top: 0;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}


.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>