<template>
  <div style="display: flex;   align-items: center;">
    <q-btn flat style="color: #FF0080" icon="arrow_back" @click="back"/>
   
  </div>
</template>

<script>
import {computed } from "vue";
import axios from "axios";
import { getCurrentInstance } from "vue";
import { useStore } from "vuex";
import router from "@/router";

export default {
    setup(){
            const store = useStore();

         const internalInstance = getCurrentInstance();
        const emitter = internalInstance.appContext.config.globalProperties.emitter;
        let treeElements = computed(()=>{return store.state.breadcrumbs})
        emitter.on("currentFolder", (e)=>{
        getPath(e.id)})
        const getPath = async (id)=>{
            try {
                store.commit("setBreadcrumbs", []);
                let response = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/path/${id}`, {timeout: 5000})
store.commit("setBreadcrumbs", response.data);
            } catch (error) {
                console.log(error)
            }
        }
        const back = async () =>{
            let id = store.state.currentFolder;
            console.log(id)
            let response = await axios.get(`${process.env.VUE_APP_BASE_URL}/parent/${id}`, {timeout: 5000})
            console.log(response);
            let parent = response.data[0].id_parent;
            store.commit("setCurrentFolder", response.data[0].id_parent);
            //emitter.emit("currentFolder", { name: response.data[0].name, id: response.data[0].id_parent });
            if(parent==null){
                router.push({ path: "/ged" });
            } else
                router.push({ path: "/folder/" + parent });
        }
        return{
            getPath,
            treeElements,
            back
        }
    }
}
</script>

<style>

</style>