import { createWebHistory, createRouter } from "vue-router";
import Subfolder from "@/components/Subfolder.vue";
import Preview from "@/views/Preview.vue";
import AddCompany from "@/components/AddCompany.vue";
import Partners from "@/views/Partners.vue";
import Mail from "@/views/Mail.vue";
import Login from "@/views/Login.vue";
import Home from "@/views/Home.vue";
import IaPreview from "@/views/IaPreview.vue"
import ZohoLogin from "@/views/ZohoLogin.vue"
import WorkForce from "@/views/WorkForce.vue"
import File from "@/views/File.vue"
import Evoliz from "@/views/Evoliz.vue"
import Ged from "../views/Ged.vue";
import Stock from "../views/Stock.vue";
import IaPreview2 from "../views/IaPreview2.vue";
import store from "../store";  
import { getAuth, onAuthStateChanged } from "firebase/auth";
const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true }


  },
  {
    path: "/ged",
    name: "GED",
    component: Ged,
    meta: { requiresAuth: true }


  },
  {
    path: "/folder/:id",
    name: "Subfolder",
    component: Subfolder,
    meta: { requiresAuth: true }

  },
  {
    path: "/preview/:name",
    name : "Preview",
    component : Preview,
    meta: { requiresAuth: true }

  },
  {
    path: "/addCompany",
    name : "addCompany",
    component : AddCompany,
    meta: { requiresAuth: true }

  },
  {
    path: "/partners",
    name : "Parnters",
    component : Partners,
    meta: { requiresAuth: true }

  },
  {
    path: "/mail",
    name : "Mail",
    component : Mail,
    meta: { requiresAuth: true }

  },
  {
    path: "/login",
    name : "Login",
    component : Login,

  },
  {
    path: '/iapreview',
    name: 'IaPreview',
    component: IaPreview,
    meta: { requiresAuth: true }

  },
  {
    path: '/iapreview2',
    name: 'IaPreview2',
    component: IaPreview2,
    meta: { requiresAuth: true }

  },
  {
    path: '/zoho',
    name: 'Zoho',
    component: ZohoLogin,
    meta: { requiresAuth: true }

  },
  {
    path: '/workforce',
    name: 'WorkForce',
    component: WorkForce,
    meta: { requiresAuth: true }

  },
  {
    path: '/file',
    name: 'File',
    component: File,
    meta: { requiresAuth: true }

  },
  {
    path: '/evoliz',
    name: 'Evoliz',
    component: Evoliz,
    meta: { requiresAuth: true }

  },
  {
    path: '/stock',
    name: 'Stock',
    component: Stock,
    meta: { requiresAuth: true }

  }
  

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


// Fonction pour vérifier si l'utilisateur est authentifié avec Firebase
const checkAuth = () => {
  const auth = getAuth();
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, user => {
      if (user) {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  });
};

// Gérer les redirections et l'authentification
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = await checkAuth(); // Vérifie si l'utilisateur est authentifié

  if (requiresAuth && !isAuthenticated) {
    // Redirige vers la page de login si l'utilisateur n'est pas connecté
    next({ name: 'Login' });
  } else {
    // Autorise l'accès à la route
    next();
  }
});


export default router;