<template>
  <div id="app">
    <Header @menuEvent="handleMenu" />
    <div class="container">
      <Menu v-if="menu" />
      <router-view v-slot="{ Component, route }">
        <Transition name="nicee" mode="out-in">
          <div :key="route.params.id" style="width:100%">
            <keep-alive>
              <component :is="Component"></component>
            </keep-alive>
          </div>
        </Transition>
      </router-view>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from 'vuex';
import Header from "@/components/Header2.vue";
import Menu from "@/components/Menu.vue";

const menu = ref(false);
const store = useStore();

// Gestion de l'état du menu
const handleMenu = () => {
  menu.value = !menu.value;
};

// Obtenir l'utilisateur et l'état de chargement depuis le store
const user = computed(() => store.getters.currentUser);
const isLoadingUser = computed(() => store.getters.isUserLoading);

</script>

<style>
body {
  background-color: #595dce12;
}

h3 {
  color: #595CCE;
}

.container {
  display: flex;
  flex-direction: row;
}

.nicee-enter-active {
  animation: opacityFromZero 0.4s ease-out;
}

.nicee-leave-active {
  animation: opacityFromZero 0.4s ease-out reverse;
}

@keyframes opacityFromZero {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>
