<template>
  <div>
    <div>
      <h3>Bienvenue {{ userName }}</h3>
    </div>
    <div>
      <div style="display: flex; justify-content: space-around">
       
      </div>
      
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, ref } from "vue";

export default {
  components: {},
  setup() {

    const store = useStore();
    let userName = ref();
    const start = () => {
      let name =
        store.state.currentClientInfos.company_manager[0].name2.split(" ");
      userName.value = name[0];
    };


    onMounted(start);
    return {
      userName,
      start,
    };
  },
};
</script>

<style>
</style>